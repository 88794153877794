import React, { useEffect } from "react";
import Map from "@arcgis/core/Map";
import SceneView from "@arcgis/core/views/SceneView";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import Polygon from "@arcgis/core/geometry/Polygon";
import PolygonSymbol3D from "@arcgis/core/symbols/PolygonSymbol3D";
import ExtrudeSymbol3DLayer from "@arcgis/core/symbols/ExtrudeSymbol3DLayer";
import "@arcgis/core/assets/esri/themes/light/main.css";

const EsriMap = () => {
  useEffect(() => {
    const map = new Map({
      basemap: "topo-vector",
      ground: "world-elevation"
    });

    const view = new SceneView({
      container: "viewDiv",
      map: map,
      camera: {
        position: [21.146644, 42.644623, 1500], // Adjust camera position for a good view
        tilt: 60
      }
    });

    const graphicsLayer = new GraphicsLayer();
    map.add(graphicsLayer);

    // Function to add buildings with each floor having a different color
    function addBuildingWithSpaces(id, rings, floors, floorHeight, colors) {
      for (let i = 0; i < floors; i++) {
        const floorPolygon = new Polygon({
          rings: rings.map(ring =>
            ring.map(coord => [coord[0], coord[1], coord[2] + i * floorHeight])
          ),
          spatialReference: { wkid: 4326 }
        });

        const floorSymbol = new PolygonSymbol3D({
          symbolLayers: [
            new ExtrudeSymbol3DLayer({
              material: { color: colors[i % colors.length] },
              size: floorHeight
            })
          ]
        });

        const floorGraphic = new Graphic({
          geometry: floorPolygon,
          symbol: floorSymbol,
          attributes: {
            id: `${id}-floor-${i + 1}`,
            floor: i + 1
          }
        });

        graphicsLayer.add(floorGraphic);
      }
    }

    // Colors for each floor
    const colors = ["#87CEFA", "#D0D0D0"];

    addBuildingWithSpaces(
      "building-2",
      [
        [
          [21.147756028367798, 42.65693034406505, 500],
          [21.148097325845722, 42.656895155046506, 500],
          [21.148145171608792, 42.65691040221101, 500],
          [21.148169094655447, 42.6569279953579, 500],
          [21.1481690952191, 42.656965527851035, 500],
          [21.148055861813017, 42.657051149770766, 500],
          [21.148298284420196, 42.657297454717, 500],
          [21.14832539731783, 42.65730801048866, 500],
          [21.148366865578826, 42.65739480406495, 500],
          [21.148164318468993, 42.65744758589654, 500],
          [21.147756028367798, 42.65693034406505, 500],
        ]
      ],
      15, // Number of floors
      10,  // Floor height (in meters)
      colors
    );

    addBuildingWithSpaces(
      "building-3",
      [
        [
          [21.147288269471453, 42.65691840125577, 600],
          [21.147868800561515, 42.65752830204758, 600],
          [21.147677417617114, 42.65761978837594, 600],
          [21.14707137002361, 42.65703099856487, 600],
          [21.146623397002635, 42.65695898210485, 600],
          [21.14662749160694, 42.65676952191845, 600]
        ]
      ],
      8, // Number of floors
      5,  // Floor height (in meters)
      colors
    );

    addBuildingWithSpaces(
      "building-4",
      [
        [
          [21.148288699361395, 42.65757641709598, 600],
          [21.148430793150503, 42.65747689438811, 600],
          [21.14891482141279, 42.657890971353105, 600],
          [21.14877150364299, 42.6579939904215, 600],
        ]
      ],
      10, // Number of floors
      10,  // Floor height (in meters)
      colors
    );

    return () => {
      if (view) {
        view.destroy();
      }
    };
  }, []);

  return <div id="viewDiv" style={{ height: "100vh", width: "100%" }}></div>;
};

export default EsriMap;